import React from "react";

const InstagramIcon = ({ size }) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="0.5"
          y1="0.9970891"
          x2="0.5"
          y2="0.007767216"
          id="gradient_1"
        >
          <stop offset="0" stopColor="#E09B3D" />
          <stop offset="0.3" stopColor="#C74C4D" />
          <stop offset="0.6" stopColor="#C21975" />
          <stop offset="1" stopColor="#7024C4" />
        </linearGradient>
        <linearGradient
          x1="0.5"
          y1="1.4609888"
          x2="0.5"
          y2="-0.45159683"
          id="gradient_2"
        >
          <stop offset="0" stopColor="#E09B3D" />
          <stop offset="0.3" stopColor="#C74C4D" />
          <stop offset="0.6" stopColor="#C21975" />
          <stop offset="1" stopColor="#7024C4" />
        </linearGradient>
        <linearGradient
          x1="0.5"
          y1="6.5814056"
          x2="0.5"
          y2="-1.4002929"
          id="gradient_3"
        >
          <stop offset="0" stopColor="#E09B3D" />
          <stop offset="0.3" stopColor="#C74C4D" />
          <stop offset="0.6" stopColor="#C21975" />
          <stop offset="1" stopColor="#7024C4" />
        </linearGradient>
      </defs>
      <g id="instagram-(2)">
        <path
          d="M13.9276 0L5.90962 0C2.65104 0 0 2.65104 0 5.90962L0 13.9276C0 17.1862 2.65104 19.8372 5.90962 19.8372L13.9276 19.8372C17.1862 19.8372 19.8372 17.1862 19.8372 13.9276L19.8372 5.90962C19.8372 2.65104 17.1861 0 13.9276 0ZM17.8416 13.9276C17.8416 16.0892 16.0892 17.8416 13.9276 17.8416L5.90962 17.8416C3.748 17.8416 1.99562 16.0892 1.99562 13.9276L1.99562 5.90962C1.99562 3.74796 3.748 1.99562 5.90962 1.99562L13.9276 1.99562C16.0892 1.99562 17.8416 3.74796 17.8416 5.90962L17.8416 13.9276L17.8416 13.9276Z"
          id="Shape"
          fill="url(#gradient_1)"
          stroke="none"
        />
        <path
          d="M5.13061 0C2.30159 0 0 2.30159 0 5.13058C0 7.95956 2.30159 10.2612 5.13061 10.2612C7.95964 10.2612 10.2612 7.9596 10.2612 5.13058C10.2612 2.30155 7.95964 0 5.13061 0ZM5.13061 8.2656C3.39919 8.2656 1.99562 6.86203 1.99562 5.13061C1.99562 3.39919 3.39923 1.99562 5.13061 1.99562C6.86203 1.99562 8.2656 3.39919 8.2656 5.13061C8.2656 6.862 6.862 8.2656 5.13061 8.2656Z"
          transform="translate(4.788 4.788)"
          id="Shape"
          fill="url(#gradient_2)"
          stroke="none"
        />
        <path
          d="M1.2294 2.4588C1.90838 2.4588 2.4588 1.90838 2.4588 1.2294C2.4588 0.550421 1.90838 0 1.2294 0C0.550421 0 0 0.550421 0 1.2294C0 1.90838 0.550421 2.4588 1.2294 2.4588Z"
          transform="translate(13.82976 3.59712)"
          id="Oval"
          fill="url(#gradient_3)"
          stroke="none"
        />
      </g>
    </svg>
  );
};

export default InstagramIcon;
